/* eslint-disable func-names */
import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { fromJS } from 'immutable';
import { useParams } from 'react-router';
import {
  roundUpPercentageString,
  isDateBetweenRanges,
  versionIndexName,
} from '../../../utils/utils';
import { TopicsTheme } from '../../../bitaComponents/BitaCharts/Themes';
import styles from './ControversyTopics.module.scss';
import '../AnalyzerComponents.scss';
import BitaCharts from '../../../bitaComponents/BitaCharts/BitaCharts';
import BitaSelect from '../../BitaSelect/BitaSelect';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';
import BitaSpinner from '../../BitaSpinner/BitaSpinner';
import BitaSearchBar from '../../../bitaComponents/BitaSearchBar/BitaSearchBar';
import ConfigYAML from '../../../config/ConfigYAML';
import { selectUniverse } from '../../../state/actions/universe-actions';
import { updateUniverse, updateMethodology } from '../../../state/actions/module-selection-actions';
import { getIndexBenchmark } from '../../../state/actions/benchmark-actions';
import * as Action from '../../../state/actions/api-data-actions';
import RunBacktestModal from '../../../pages/runBacktestModal';
import RunRebalancingReconstitution from '../../../pages/runRebalancingReconstitution';
import { DownloadCenterModal } from '../../BitaModal/DownloadCenterModal';
import { IconsModules } from '../../../constants/icons-module';
import IndexDescription from '../IndexDescription';
import DownloadCenter from '../DownloadCenter';
import { controversyTopics, sustainalytics } from './lib/Data';
import TablesStats from './lib/TableStats';

const PerformanceAndRisk = ({ benchmarkList }) => {
  const [indexStats, setIndexStats] = useState(fromJS({}));
  const [pseudoTimeout, setPseudoTimeout] = useState(false);
  const [benchmarkStats, setBenchmarkStats] = useState(fromJS({}));
  const [selectedTimeFrame, setSelectedTimeFrame] = useState('5y');
  const [selectedTimeFrameTable, setSelectedTimeFrameTable] = useState();
  const [datatimeframe, setDatatimeframe] = useState([]);
  const [indexValues, setIndexValues] = useState([]);
  const [benchmarkValues, setBenchmarkValues] = useState([]);
  const optionsBaseDefault = benchmarkList || [];
  const [riskModel, setRiskModel] = useState(11);
  const [optionsBase, setOptionsBase] = React.useState(optionsBaseDefault[0]);
  const apiData = useSelector(state => state.apiData);
  const company = useSelector(state => state.auth.company);
  const backtestData = useSelector(state => state.backtest);
  const [indexBenchmark, setIndexBenchmark] = useState();
  const [provider, setProvider] = useState({ id: 'Arabesque', value: 'Arabesque' });
  const [widgetSelected, setWidgetSelected] = useState({
    id: 'Preference Filters',
    value: 'Preference Filters',
  });
  const [controversyAllocationSelect, setControversyAllocationSelect] = useState(
    provider.id === 'Arabesque'
      ? controversyTopics[controversyTopics.length - 1]
      : sustainalytics[sustainalytics.length - 1],
  );
  const [sustainalyticsTable, setSustainalyticsTable] = useState([]);
  const [controversyTable, setControversyTable] = useState([]);
  const [dataChartControversy, setDataChartControversy] = useState();
  const [dataChartSustainalytics, setDataChartSustainalytics] = useState();

  const dispatch = useDispatch();
  const modalRef = useRef();
  const ModalRRRef = useRef();

  const index_list = _.get(apiData, ['apiData', 'Indexes', 'data'], []);
  const currentIndexSelected = useSelector(
    state => state.apiData.apiData['Current Index Selected'],
  );
  const token = useSelector(state => state.auth.token);

  const { indexId } = useParams();

  useEffect(() => {
    if (apiData.apiData && apiData.apiData['Index Stats']) {
      setIndexStats(fromJS(apiData.apiData['Index Stats']));
    }
  }, [apiData.apiData['Index Stats']]);

  useEffect(() => {
    if (apiData.apiData && apiData.apiData['Benchmark Card Stats']) {
      setBenchmarkStats(fromJS(apiData.apiData['Benchmark Card Stats']));
    }
  }, [apiData.apiData['Benchmark Card Stats']]);

  const getDateFromTimestamp = timestamp => {
    return moment(timestamp, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
  };

  let ImageI;
  if (currentIndexSelected?.data?.index_type === 'regular') {
    ImageI = IconsModules.IndexModule;
  } else if (currentIndexSelected?.data?.index_type === 'optimized_index') {
    ImageI = IconsModules.OptimizedModule;
  } else if (currentIndexSelected?.data?.index_type === 'basket_index') {
    ImageI = IconsModules.PortfolioBasketModule;
  } else {
    ImageI = IconsModules.StructureIModule;
  }

  useEffect(() => {
    if (currentIndexSelected.data && currentIndexSelected.data.benchmark_id) {
      setIndexBenchmark(currentIndexSelected.data.benchmark_id);

      const usedBenchmark = optionsBaseDefault.filter(
        bench => bench.id === currentIndexSelected.data.benchmark_id,
      );
      if (usedBenchmark.length > 0) setOptionsBase(usedBenchmark[0]);
      const riskModelList = _.get(
        apiData,
        ['apiData', 'risk_models', 'data', 'Risk Model Bita'],
        [],
      );
      const risk = riskModelList.filter(r => r.name === currentIndexSelected.data.risk_name);
      if (risk.length > 0) {
        setRiskModel(risk[0]);
      }
    }
  }, [currentIndexSelected, benchmarkList]);

  useEffect(() => {
    if (indexId && indexBenchmark) {
      getIndexBenchmark(indexId, indexBenchmark, token).then(resp => {
        dispatch(
          Action.setApiData({
            title: 'Benchmark Card Stats',
            data: resp.data,
          }),
        );
      });
    }
  }, [indexBenchmark]);

  useEffect(() => {
    if (indexId) {
      dispatch(Action.setCurrentIndex(token, indexId));
      dispatch(Action.setIndexStats(token, indexId));
    }
  }, [indexId]);

  useLayoutEffect(() => {
    // const arr = _.get(indexStats, `data.[45].value`, []);
    const arr = indexStats.getIn(['data', '45', 'value']) || fromJS([]);
    const formattedValues = arr
      .sort((a, b) => {
        const currentDate = new Date(a.get('timestamp'));
        const nextDate = new Date(b.get('timestamp'));
        if (currentDate < nextDate) {
          return 1;
        }
        if (currentDate > nextDate) {
          return -1;
        }
        return 0;
      })
      .map(stat =>
        fromJS({
          date: stat.get('timestamp'),
          x: new Date(stat.get('timestamp')),
          y: roundUpPercentageString(stat.get('value')),
          name: stat.get('timestamp'),
        }),
      );

    setIndexValues(formattedValues.slice().reverse());
  }, [indexStats]);

  useLayoutEffect(() => {
    const arr = benchmarkStats.getIn(['data', '45', 'value']) || fromJS([]);

    const formattedValues = arr
      .sort((a, b) => {
        const currentDate = new Date(a.get('timestamp'));
        const nextDate = new Date(b.get('timestamp'));
        if (currentDate < nextDate) {
          return 1;
        }
        if (currentDate > nextDate) {
          return -1;
        }
        return 0;
      })
      .map(stat =>
        fromJS({
          date: stat.get('timestamp'),
          y: roundUpPercentageString(stat.get('value')),
          x: new Date(stat.get('timestamp')),
          name: new Date(stat.get('timestamp')),
        }),
      );

    setBenchmarkValues(formattedValues.slice().reverse());
  }, [benchmarkStats]);

  const isSameAsBacktest = () => {
    if (
      backtestData &&
      currentIndexSelected &&
      currentIndexSelected.data &&
      backtestData.id === currentIndexSelected.data.id
    ) {
      return true;
    }
    return false;
  };

  const filesDownloadCenter = isSameAsBacktest()
    ? _.get(backtestData, 'pdfs', {
        status: 'loading',
      })
    : _.get(apiData, 'apiData.[Download Center]', {
        status: 'loading',
      });

  const timer = ConfigYAML.jsonPath(['analyzer', 'timeout_files']);
  const filesSpinnerMessage = ConfigYAML.jsonPath(['analyzer', 'timeout_text']);
  useEffect(() => {
    if (indexStats.size > 0) {
      setTimeout(() => {
        setPseudoTimeout(true);
      }, timer);
    }
  }, [indexStats]);

  const getMethodology = idMeth => {
    const allMethodologies = _.get(apiData, 'apiData.[Methodologies]', {});
    const selectedMeth = allMethodologies.data.filter(m => m.id === idMeth)[0];
    if (selectedMeth) {
      dispatch(updateMethodology(selectedMeth));
      dispatch(
        Action.setApiData({
          title: 'methodologySelected',
          data: { ...selectedMeth, ...selectedMeth.rules, id: idMeth },
        }),
      );
      dispatch(
        Action.setApiData({
          title: 'methodologySelectedCard',
          data: { ...selectedMeth, ...selectedMeth.rules, id: idMeth },
        }),
      );
    }
  };

  const getUniverse = idUniverse => {
    const allUniverses = _.get(apiData, 'apiData.[AllUniverses]', {});
    const selectedUni = allUniverses.data.filter(u => u.id === idUniverse)[0];
    if (selectedUni) {
      dispatch(selectUniverse(selectedUni));
      dispatch(updateUniverse(selectedUni));
      dispatch(
        Action.setApiData({
          title: 'universeSelected',
          data: selectedUni,
        }),
      );
      dispatch(
        Action.setApiData({
          title: 'universeSelectedCard',
          data: selectedUni,
        }),
      );
    }
  };

  const activeRunBackTest = () => {
    if (currentIndexSelected && currentIndexSelected.data) {
      const idUniverse = currentIndexSelected.data.universe_id;
      const idMethodology = currentIndexSelected.data.methodology_id;
      getUniverse(idUniverse);
      getMethodology(idMethodology);
      dispatch(
        Action.setApiData({
          title: 'indexBacktest',
          data: {
            'Index Backtest': {
              'INDEX NAME': versionIndexName(currentIndexSelected.data.name, index_list),
              'BASE VALUE': Math.trunc(currentIndexSelected.data.base_value),
              'Start Date': currentIndexSelected.data.start_date.split('T')[0],
              'Inception Date': currentIndexSelected.data.inception_date.split('T')[0],
              Benchmark: optionsBase,
              risk_model_id: riskModel,
              RiskOption: riskModel,
            },
          },
        }),
      );
      modalRef.current.runModal();
    }
    return {};
  };

  const activeRebalanceReconstitution = () => {
    dispatch(
      Action.setApiData({
        title: 'indexBacktest',
        data: {
          'Index Backtest': {
            'PORTFOLIO NAME': versionIndexName(currentIndexSelected.data.name, index_list),
            'BASE VALUE': Math.trunc(currentIndexSelected.data.base_value),
            'Start Date': currentIndexSelected.data.start_date.split('T')[0],
            'Inception Date': currentIndexSelected.data.inception_date.split('T')[0],
            Benchmark: optionsBase,
            risk_model_id: riskModel,
            RiskOption: riskModel,
          },
        },
      }),
    );
    const idUniverse = currentIndexSelected.data.universe_id;
    const idMethodology = currentIndexSelected.data.methodology_id;
    getUniverse(idUniverse);
    getMethodology(idMethodology);
    ModalRRRef.current.runModal();
  };

  const getIndexName = () => {
    if (currentIndexSelected && currentIndexSelected.data) {
      return currentIndexSelected.data.name;
    }
    return '';
  };

  const dataDropdowns = {
    Arabesque: [{ id: 'Preference Filters', value: 'Preference Filters' }],
    Sustainalytics: [{ id: 'ESG Risk Issues', value: 'ESG Risk Issues' }],
  };

  /* const baseInfo = ConfigYAML.jsonPath([
    'analyzer',
    "widgets[?(@.title == 'Universal Global')]",
    'components',
  ]); */
  const generalStats = ConfigYAML.jsonPath([
    'analyzer',
    "widgets[?(@.title == 'General Statistics')]",
    'components',
  ]);

  const tableData = {
    index: indexStats && indexStats.get('data')?.toJS(),
    benchmark: benchmarkStats && benchmarkStats.get('data')?.toJS(),
  };
  const getInterval = () => {
    switch (selectedTimeFrame) {
      case 'YTD':
        return 86400000 * 31;
      case '1Y':
        return 86400000 * 31;
      default:
        return 86400000 * 31 * 6;
    }
  };

  const performanceChartOptions = {
    xAxis: {
      categories: dataChartControversy?.timeframeCharp,
    },
    series: [
      {
        name:
          currentIndexSelected?.data?.index_type === 'rebalancing_module' ? 'PORTFOLIO' : 'INDEX',
        data:
          dataChartControversy?.index?.map(item => {
            return Number(item);
          }) || [],
      },
      {
        name: 'BENCHMARK',
        data:
          dataChartControversy?.benchmark?.map(item => {
            return Number(item);
          }) || [],
      },
    ],
  };

  const sustainalyticsChartOptions = {
    xAxis: {
      categories: dataChartSustainalytics?.timeframeCharp,
    },
    series: [
      {
        name:
          currentIndexSelected?.data?.index_type === 'rebalancing_module' ? 'PORTFOLIO' : 'INDEX',
        data:
          dataChartSustainalytics?.index?.map(item => {
            return Number(item);
          }) || [],
      },
      {
        name: 'BENCHMARK',
        data:
          dataChartSustainalytics?.benchmark?.map(item => {
            return Number(item);
          }) || [],
      },
    ],
  };

  function sortAndSplit(array, _dtimeframe) {
    const sortedData = array.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
    const slicedData = sortedData.splice(_dtimeframe * -4);
    return slicedData;
  }

  const calculateDataTotalAllocation = () => {
    // TODO refactor this code to make it more understandable and maintainable over time by Karem
    let dataIndex = [];
    let dataBenchmark = [];
    const _datatimeframe = selectedTimeFrame === '1y' ? 1 : selectedTimeFrame === '3y' ? 3 : 5;
    const getDatasList = provider.id === 'Arabesque' ? controversyTopics : sustainalytics;
    getDatasList.forEach(item => {
      const _benchmark = benchmarkStats
        .get('data')
        ?.toJS()
        ?.[item.id]?.value?.sort(
          (a, b) => getDateFromTimestamp(a.timestamp) - getDateFromTimestamp(b.timestamp),
        );
      const _index = indexStats
        .get('data')
        ?.toJS()
        ?.[item.id]?.value?.sort(
          (a, b) => getDateFromTimestamp(a.timestamp) - getDateFromTimestamp(b.timestamp),
        );
      dataIndex = dataIndex.concat(_index);
      dataBenchmark = dataBenchmark.concat(_benchmark);
    });
    const dataTotalIndex = [];
    const dataTotalBenchmark = [];
    const dataTotalTimeframe = [];
    Object.entries(_.groupBy(dataIndex, obj => obj?.timestamp)).forEach(item => {
      const sumIndex =
        item[1].reduce(
          (sum, value) =>
            typeof value?.value?.weight === 'number' ? sum + value?.value?.weight : sum,
          0,
        ) * 100;
      const sumBenchmark =
        _.groupBy(dataBenchmark, obj => obj?.timestamp)?.[item[0]]?.reduce(
          (sum, value) =>
            typeof value?.value?.weight === 'number' ? sum + value?.value?.weight : sum,
          0,
        ) * 100;
      if (item[0] !== 'undefined') {
        const timeframe = item[0];
        dataTotalIndex.push(sumIndex.toFixed(2));
        dataTotalBenchmark.push(sumBenchmark.toFixed(2));
        dataTotalTimeframe.push(
          `Q${moment(timeframe)
            .utc()
            .quarter()}-${moment(timeframe).year()}`,
        );
      }
    });

    if (provider.id === 'Arabesque') {
      setDataChartControversy({
        timeframeCharp: sortAndSplit(dataTotalTimeframe, _datatimeframe),
        index: sortAndSplit(dataTotalIndex, _datatimeframe),
        benchmark: sortAndSplit(dataTotalBenchmark, _datatimeframe),
      });
    } else {
      setDataChartSustainalytics({
        timeframeCharp: sortAndSplit(dataTotalTimeframe, _datatimeframe),
        index: sortAndSplit(dataTotalIndex, _datatimeframe),
        benchmark: sortAndSplit(dataTotalBenchmark, _datatimeframe),
      });
    }
  };

  const GeneralStatsPITButtons = [
    { name: '1 Year', value: '1y' },
    { name: '3 Years', value: '3y' },
    { name: '5 Years', value: '5y' },
  ];

  useEffect(() => {
    setDataChartControversy({});
    setDataChartSustainalytics({});
    setControversyAllocationSelect(
      provider.id === 'Arabesque'
        ? controversyTopics[controversyTopics.length - 1]
        : sustainalytics[sustainalytics.length - 1],
    );
  }, [provider]);

  useEffect(() => {
    const _dataControversy = [];
    controversyTopics.forEach(item => {
      const _benchmark = benchmarkStats.get('data')?.toJS()?.[item.id]?.value;
      const _index = indexStats.get('data')?.toJS()?.[item.id]?.value;
      if (_index?.length && _benchmark?.length && selectedTimeFrameTable?.id) {
        const ControversyItem = {
          name: item.name,
          index: _index.find(j => j.timestamp.includes(selectedTimeFrameTable.id)),
          benchmark: _benchmark.find(j => j.timestamp.includes(selectedTimeFrameTable.id)),
        };
        _dataControversy.push(ControversyItem);
      }
    });
    setControversyTable(_dataControversy);
  }, [indexStats, benchmarkStats, selectedTimeFrameTable]);

  useEffect(() => {
    const _dataSustainalytics = [];
    sustainalytics.forEach(item => {
      const _benchmark = benchmarkStats.get('data')?.toJS()?.[item.id]?.value;
      const _index = indexStats.get('data')?.toJS()?.[item.id]?.value;
      if (_index?.length && _benchmark?.length && selectedTimeFrameTable?.id) {
        const ControversyItem = {
          name: item.value,
          index: _index.find(j => j.timestamp.includes(selectedTimeFrameTable.id)),
          benchmark: _benchmark.find(j => j.timestamp.includes(selectedTimeFrameTable.id)),
        };
        _dataSustainalytics.push(ControversyItem);
      }
    });
    setSustainalyticsTable(_dataSustainalytics);
  }, [indexStats, benchmarkStats, selectedTimeFrameTable]);

  useEffect(() => {
    const _dataControversy = [];
    const dateList = Object.keys(_.get(currentIndexSelected, 'data.dates_constitutions_real', {}))
      .slice()
      .reverse();
    const _index = indexStats.get('data')?.toJS()?.[controversyTopics[0].id]?.value;
    (_index || []).forEach((item, key) => {
      _dataControversy.push({
        id: moment(item.timestamp).format('YYYY-MM'),
        value: key === 0 && dateList[0] ? dateList[0] : moment(item.timestamp).format('YYYY-MM-DD'),
      });
    });
    setDatatimeframe(_dataControversy);
    setSelectedTimeFrameTable(_dataControversy[0]);
  }, [indexStats]);

  useEffect(() => {
    // TODO refactor this code to make it more understandable and maintainable over time by Karem
    if (controversyAllocationSelect.id === 'Total Allocation') {
      calculateDataTotalAllocation();
    } else {
      const _benchmark =
        benchmarkStats.get('data')?.toJS()?.[controversyAllocationSelect?.id]?.value || [];
      const _index = indexStats.get('data')?.toJS()?.[controversyAllocationSelect?.id]?.value || [];
      const _datatimeframe = selectedTimeFrame === '1y' ? 1 : selectedTimeFrame === '3y' ? 3 : 5;

      _benchmark.sort(
        (a, b) => getDateFromTimestamp(a.timestamp) - getDateFromTimestamp(b.timestamp),
      );
      _index.sort((a, b) => getDateFromTimestamp(a.timestamp) - getDateFromTimestamp(b.timestamp));

      if (_index.length > 0) {
        const start_date = moment(_index[0].timestamp, 'YYYY-MM-DDTHH:mm:ss.SSSZ').subtract(
          _datatimeframe,
          'years',
        );
        const auxIndex =
          selectedTimeFrame === 'max'
            ? _index
            : _index.filter(function(data) {
                return moment(data.timestamp, 'YYYY-MM-DDTHH:mm:ss.SSSZ').isSameOrAfter(start_date);
              });
        const dataStats = _index.filter(
          item => item.timestamp.includes('12-31') || item.timestamp.includes('12-30'),
        );
        const datamaxBenchmark = _benchmark.filter(
          item => item.timestamp.includes('12-31') || item.timestamp.includes('12-30'),
        );

        const _valCharp = [];
        const timeframeCharp = [];
        (selectedTimeFrame === 'max' ? dataStats : auxIndex).forEach(item => {
          timeframeCharp.push(
            selectedTimeFrame !== 'max'
              ? `Q${moment(item.timestamp)
                  .utc()
                  .quarter()}-${moment(item.timestamp).year()}`
              : moment(item.timestamp).year(),
          );
          _valCharp.push(Number(item.value.weight * 100).toFixed(2));
        });

        const _valCharpBenchmark = [];
        const auxBenchmark =
          selectedTimeFrame === 'max'
            ? _benchmark
            : _benchmark.filter(function(data) {
                return moment(data.timestamp, 'YYYY-MM-DDTHH:mm:ss.SSSZ').isSameOrAfter(start_date);
              });
        (selectedTimeFrame === 'max' ? datamaxBenchmark : auxBenchmark).forEach(item => {
          _valCharpBenchmark.push(Number(item.value.weight * 100).toFixed(2));
        });
        const dataCopy = {
          timeframeCharp:
            selectedTimeFrame === 'max'
              ? [...timeframeCharp]
              : [...timeframeCharp.splice(_datatimeframe * -4)],
          index: selectedTimeFrame === 'max' ? _valCharp : _valCharp.splice(_datatimeframe * -4),
          benchmark:
            selectedTimeFrame === 'max'
              ? _valCharpBenchmark
              : _valCharpBenchmark.splice(_datatimeframe * -4),
        };
        setDataChartControversy(dataCopy);
        setDataChartSustainalytics(dataCopy);
      } else {
        setDataChartControversy({ timeframeCharp: [], index: [], benchmark: [] });
        setDataChartSustainalytics({ timeframeCharp: [], index: [], benchmark: [] });
      }
    }
  }, [indexStats, controversyAllocationSelect, selectedTimeFrame, provider]);

  return (
    <div className={styles.base}>
      <RunBacktestModal ubication="analyzer" showData ref={modalRef} disable_title />
      <RunRebalancingReconstitution ubication="analyzer" showData ref={ModalRRRef} disable_title />
      <DownloadCenterModal />
      <div className={styles.contentContainer}>
        <div className={styles.firstHalfContainer}>
          <div className={styles.halfWidthContainer}>
            <div className={styles.baseInfoContainer}>
              <div className={styles.baseInfoTitleContainer}>
                <span className={styles.baseInfoTitle}>
                  {' '}
                  <ImageI /> {getIndexName()}
                </span>
              </div>
              <IndexDescription
                indexType={currentIndexSelected?.data?.index_type}
                universe={currentIndexSelected?.data?.universe || ''}
                methodology={currentIndexSelected?.data?.methodology || ''}
                start={currentIndexSelected?.data?.start_date.split('T')[0] || ''}
                end={currentIndexSelected?.data?.end_date?.split('T')[0] || ''}
                author={currentIndexSelected?.data?.author || ''}
                benchmark={currentIndexSelected?.data?.benchmark || ''}
                risk={currentIndexSelected?.data?.risk_name || ''}
                parentIndex={currentIndexSelected?.data?.parent_index}
                frontParameters={currentIndexSelected?.data?.front_parameters || {}}
              />
              {currentIndexSelected?.data?.index_type === 'regular' && (
                <div
                  className={styles.benchmarkContainer}
                  style={{
                    marginTop:
                      currentIndexSelected?.data?.index_type === 'optimized_index'
                        ? '-15px'
                        : '0px',
                  }}
                >
                  <span className={styles.benchmarkTitle}>Select New Benchmark</span>
                  <div className={styles.benchmarkSearch}>
                    <div className={styles.benchmarkSearchDropdown}>
                      <BitaSearchBar
                        searchOptions={optionsBaseDefault}
                        onChange={selected => {
                          setOptionsBase(selected);
                        }}
                        value={optionsBase?.value}
                      />
                    </div>
                    <BitaButton
                      primaryWhite
                      onClick={activeRunBackTest}
                      disabled={currentIndexSelected?.data?.index_type === 'structured_index'}
                    >
                      Recalculate
                    </BitaButton>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.downloadContainer}>
              <span className={styles.downloadTitle}>Download Center</span>
              {(filesDownloadCenter.status === 'done' || filesDownloadCenter?.files) &&
              pseudoTimeout ? (
                <DownloadCenter
                  files={Object.assign(
                    {
                      constituents: currentIndexSelected?.data?.constituents_url,
                      machine_readable_constituents_url:
                        currentIndexSelected?.data?.machine_readable_constituents_url,
                      machine_readable_constituents_summary_url:
                        currentIndexSelected?.data?.machine_readable_constituents_summary_url,
                      machine_readable_basket_management_constituents_url:
                        currentIndexSelected?.data?.machine_readable_basket_management_constituents_url,
                      price: currentIndexSelected?.data?.price_series_url,
                      guidebook: currentIndexSelected?.data?.guidebook_url,
                    },
                    filesDownloadCenter.files,
                  )}
                  company={company}
                  indexType={currentIndexSelected?.data?.index_type}
                  frontParameters={currentIndexSelected?.data?.front_parameters || {}}
                />
              ) : (
                <div>
                  <BitaSpinner />
                  {filesSpinnerMessage}
                </div>
              )}
            </div>
          </div>
          <div
            className={styles.generalStatsContainer}
            style={{ width: '60%', padding: '0px 20px' }}
          >
            {(currentIndexSelected?.data?.index_type === 'regular' ||
              currentIndexSelected?.data?.index_type === 'rebalancing_module') && (
              <div
                style={{
                  position: 'relative',
                  top: '-65px',
                  left: '20px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <BitaButton
                  primaryWhite
                  style={{ width: '200px', marginRight: '10px' }}
                  onClick={activeRebalanceReconstitution}
                >
                  Rebalance Portfolio/Index
                </BitaButton>
              </div>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '20px',
              }}
            >
              <span className={styles.styleFactorTitle} style={{ fontSize: '15px' }}>
                {provider.id === 'Arabesque' ? 'ESG Issues' : 'ESG Risk Issues'}
              </span>
              <div
                style={{
                  display: 'flex',
                  zIndex: '999999999999999999',
                }}
              >
                <BitaSelect
                  data={[
                    { id: 'Arabesque', value: 'Arabesque' },
                    { id: 'Sustainalytics', value: 'Sustainalytics' },
                  ]}
                  value={provider}
                  clearable={false}
                  handleSelection={selected => {
                    setProvider(selected);
                    setWidgetSelected(dataDropdowns[selected?.id ? selected.id : 'Arabesque']?.[0]);
                  }}
                  style={{ marginRight: '10px' }}
                />
                <BitaSelect
                  data={dataDropdowns[provider.id]}
                  value={widgetSelected}
                  handleSelection={selected => {
                    setWidgetSelected(selected || dataDropdowns[provider.id]?.[0]);
                  }}
                />
              </div>
            </div>
            <div
              className={styles.radioButtonsContainer}
              style={{
                justifyContent: 'flex-end',
                fontSize: '11px',
              }}
            >
              {GeneralStatsPITButtons.map(button => (
                <div>
                  <input
                    id={button.value}
                    type="radio"
                    name="radio-filtertables"
                    onClick={() => setSelectedTimeFrame(button.value)}
                    checked={selectedTimeFrame === button.value}
                  />
                  <label htmlFor={button.value}>{button.name}</label>
                </div>
              ))}
            </div>
            <div>
              <div className={styles.tableContainer}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <span style={{ fontWeight: 'bold', marginLeft: '10px' }}>
                    {provider.id === 'Arabesque'
                      ? 'Historical Allocation'
                      : 'Historical Allocation'}
                  </span>
                  <BitaSelect
                    data={provider.id === 'Arabesque' ? controversyTopics : sustainalytics}
                    handleSelection={selected => {
                      setControversyAllocationSelect(selected);
                    }}
                    value={controversyAllocationSelect}
                  />
                </div>
                <div>
                  <BitaCharts
                    key="historicalPerformanceChart"
                    options={{
                      ...(provider.id === 'Arabesque'
                        ? performanceChartOptions
                        : sustainalyticsChartOptions),
                    }}
                    theme={TopicsTheme}
                    containerProps={{
                      style: { padding: 20, minHeight: '300px', height: '100%', width: '100%' },
                    }}
                  />
                </div>
              </div>
              <div className={styles.tableContainer}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '10px',
                  }}
                >
                  <span style={{ fontWeight: 'bold', marginLeft: '10px' }}>
                    {provider.id === 'Arabesque'
                      ? 'Preference Filters Allocation'
                      : 'ESG Risk Issues Allocation'}
                  </span>
                  <BitaSelect
                    data={datatimeframe}
                    handleSelection={selected => {
                      setSelectedTimeFrameTable(selected);
                    }}
                    value={selectedTimeFrameTable}
                  />
                </div>
                <TablesStats
                  data={provider.id === 'Arabesque' ? controversyTable : sustainalyticsTable}
                  typeIndex={currentIndexSelected?.data?.index_type}
                  type={provider.id}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PerformanceAndRisk;
