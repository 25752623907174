/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { Nav } from 'react-sidenav';
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import NavItem from '../../bitaComponents/SideNavbar/lib/NavItem';
import NavTitle from '../../bitaComponents/SideNavbar/lib/NavTitle';
import SideNavbar from '../../bitaComponents/SideNavbar/SideNavbar';
import logo from '../../static/img/BITACore_Top_Logo-Background.svg';
import styles from './Sidebar.module.scss';
import SideUserbar from './SideUserbar';
import { hasValidUBS, hasValidIdBNP, hasValidMV_OPSOnly } from '../../utils/utils';

import { sideBarNavigationItems, userItems } from './MainSideBarStructure';
import { Icons } from '../../constants/icons';
import SideNavWrapper from './SideNavWrapper';
import commonReducer from '../../state/reducers/common-reducer';

/*
SubNav is used inside a NavTitle (which provide the title for the submenu) this build the nested menu
and items recursivelly
 */
const SubNav = (item, key) => {
  let _key = 0;
  return (
    <Nav id={`${key}`} key={`nav-${key}`}>
      <NavItem
        key={`navitem-${key}`}
        title={item.title}
        to={item.to}
        icon={item.icon}
        banner={item?.banner}
      />
      {item.childs &&
        item.childs.map(_item => {
          _key += 1;
          return SubNav(_item, _key);
        })}
    </Nav>
  );
};

const SideBarItemFactory = ({ to, title, items, content, hideFilter, status }) => {
  const location = useLocation();
  const apiData = useSelector(state => state.apiData.apiData);
  const [activeLiveBasket, setActiveLiveBaskert] = useState(false);

  if (
    title.toUpperCase() === 'ANALYZER' &&
    _.get(apiData, ['Current Index Selected', 'data', 'index_type'], false) === 'structured_index'
  ) {
    items = items.filter(item => item.title !== 'ESG Analytics');
  }

  if (title.toUpperCase() === 'ANALYZER' && location.search.includes('live_index')) {
    items = items.map(item => {
      return { ...item, to: `${item.to}?type=live_index` };
    });
  }

  useEffect(() => {
    return () => {
      // console.info('unmounting SideBarItemFactory');
    };
  }, []);
  // console.info('SIDEBAR ', title);
  // only takes first path like /some/some ---> /some
  let _to = /\/[A-Za-z-_]+/.exec(to);
  _to = (_to && _to.length && _to[0]) || to;

  // SideBarItemFactory return
  // console.info('TO IS ', to, ' title is ', title, ' items ', JSON.stringify(items));

  const subNavItems =
    items &&
    // eslint-disable-next-line consistent-return
    items.map((item, index) => {
      const key = `${title} ${index}`;
      if (
        !(
          _.get(apiData, ['Current Index Selected', 'data', 'index_type'], false) ===
            'rebalancing_module' && item.title === 'ESG Analytics'
        )
      ) {
        return SubNav(item, key);
      }
    });
  const defaultPath = _to + _to;
  const subMenu = (
    <SideNavWrapper
      defaultPath={defaultPath}
      hideFilter={title === 'ANALIZER' && location.pathname === '/index-analyzer/analyzer'}
    >
      {/* this is the filter item title */}
      <NavItem
        selected={false}
        key={`filtergroup-${title}`}
        to={`${defaultPath}/filtergroup`}
        title="Available Rules"
        icon={Icons.filters}
      />

      {subNavItems}
    </SideNavWrapper>
  );

  const mustHideItems = () => hideFilter && location.pathname === to;

  return (
    <Nav id={`${title}${items && items.length}`}>
      <NavTitle
        title={title}
        to={
          title === 'BASKET MANAGEMENT MODULES'
            ? location.search !== '?menu=live'
              ? `${location.pathname}?menu=live`
              : location.pathname
            : to
        }
        collapsed={
          title === 'BASKET MANAGEMENT MODULES' &&
          (location.search === '?menu=live' || location.pathname.includes('live-calculation'))
            ? false
            : !location.pathname.includes(_to)
        }
        status={status}
      >
        {title === 'BASKET MANAGEMENT MODULES' &&
        (location.search === '?menu=live' || location.pathname.includes('live-calculation'))
          ? subNavItems
          : content || (!mustHideItems() && items && (!hideFilter ? subMenu : subNavItems))}
      </NavTitle>
    </Nav>
  );
};

const MainLeftSidebar = props => {
  const location = useLocation();
  const currentPath = location.pathname;
  const userPlan = {};
  const auxPlan = useSelector(state => state.auth.plan.modules);
  const apiData = useSelector(state => state.apiData.apiData);
  const subHeader = useSelector(state => state.common.subheader);
  const userdata = useSelector(state => state.auth.userdata);
  const showBasket =
    hasValidUBS(userdata.id_company) ||
    hasValidIdBNP(userdata.id_company) ||
    hasValidMV_OPSOnly(userdata.id_company) ||
    userdata.id_company === process.env.REACT_APP_BITA_ID ||
    userdata.id_company === process.env.REACT_APP_DEMO_COMPANY_ID;

  // eslint-disable-next-line array-callback-return
  if (auxPlan) {
    auxPlan.map(module => {
      userPlan[module.name] = module.status_plan;
    });
  }

  useEffect(() => {
    return () => {
      // console.info('unmounting MAINLEFTSIDEBAR');
    };
  }, [props]);

  let key = 0;
  /* const mustExpandFilter = !!/\/universe-builder\/universe-builder\/filtergroup-\d+\/.+/.exec(
    currentPath,
  ); */
  return (
    <div className={styles.sidebar}>
      <img className={styles.logo} src={logo} alt="logo" />
      <div className={classnames(styles.container, styles.sidebarNavigation)} data-simplebar>
        <div>
          <SideNavbar selectedId={currentPath}>
            {/* sidebarNavigationItems are Universe Builder, Index Builder, etc */}
            {sideBarNavigationItems
              ?.filter(
                item =>
                  item.title === 'HOME' ||
                  (!hasValidUBS(userdata.id_company) &&
                    item.title !== 'BASKET MANAGEMENT MODULES') ||
                  (item.title === 'BASKET MANAGEMENT MODULES' && showBasket),
              )
              .map((sideBarItem, index) => (
                <SideBarItemFactory
                  key={sideBarItem.index || index}
                  conditionalRendering={sideBarItem.conditionalRendering || true}
                  title={
                    sideBarItem.title === 'PORTFOLIO / BASKET CALCULATION'
                      ? 'PORTFOLIO / BASKET BACKTESTING'
                      : sideBarItem.title
                  }
                  to={
                    subHeader === 'CREATE NEW METHODOLOGY · WHITE CANVAS' &&
                    sideBarItem.title === 'INDEX BUILDER'
                      ? '/index-builders/rebalancing-reconstitution'
                      : sideBarItem.to
                  }
                  items={
                    hasValidUBS(userdata.id_company)
                      ? sideBarItem?.items?.filter(
                          subItem => subItem.title !== 'BASKET TO INDEX CONV.',
                        )
                      : hasValidMV_OPSOnly(userdata.id_company)
                      ? sideBarItem?.items?.filter(
                          subItem =>
                            subItem.title !== 'BASKET TO INDEX CONV.' &&
                            subItem.title !== 'BULK BASKET SUBMISSION',
                        )
                      : sideBarItem.items
                  }
                  content={sideBarItem.content}
                  hideFilter={sideBarItem.hideFilter || false}
                  status={
                    sideBarItem.title === 'HOME' ||
                    sideBarItem.title === 'SINGLE BASKET SUBMISSION' ||
                    sideBarItem.title === 'BASKET UPDATE/EDITION' ||
                    sideBarItem.title === 'BASKET MANAGEMENT MODULES'
                      ? 'ACTIVE'
                      : userPlan[
                          sideBarItem.title === 'STRUCTURED INDEX'
                            ? 'STRUCTURED INDEX BUILDER'
                            : sideBarItem.title === 'OPTIMIZED INDEX'
                            ? 'OPTIMIZED INDEX BUILDER'
                            : sideBarItem.title.toUpperCase()
                        ]
                  }
                />
              ))}
          </SideNavbar>
        </div>
      </div>
      <div>
        <Nav id="separator">
          <div className={styles.sidebarSeparatorBox}>
            <div className={styles.sidebarSeparator} />
          </div>
        </Nav>
        <Nav id="userbar">
          <SideUserbar />
        </Nav>
        {userItems.map(item => {
          key += 1;
          return SubNav(item, key);
        })}
      </div>
    </div>
  );
};

export default MainLeftSidebar;
